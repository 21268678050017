import { getResources } from "../fakeResourceService";
import { useLoaderData,} from "react-router-dom";
import { useEffect, useState } from "react";

import dayjs from "dayjs";
import { LineChart } from '@mui/x-charts/LineChart';
import { styled } from '@mui/material/styles';
import Paper from "@mui/material/Paper";
import Grid from '@mui/material/Grid';

const xAxisData = [
  ];
  const seriesData = [

  ];


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(3),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

function ResourceContainer (props) {
    console.log(props)
    const resources = props.resources ? props.resources:[];

    return (
        <Grid container spacing={2}>
            {resources.map((resource, index) => (
                <Grid item xs={8}>
                    <Item elevation={3}>
                        {resource}
                    </Item>
                </Grid>
            ))}
        </Grid>
    );
}

const Resources = () => {
    const [res, setResources] = useState(null);
    useEffect(() => {
        getResources().then(resources => setResources(resources));
        
    },[]);
    return (
        <>
                <ResourceContainer resources={res} />  
        </>
    );
};

export default function InsightsDashboard() {
    return (
        <> 
        </>
    );
  }