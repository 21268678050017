import * as React from "react";
import * as ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import ErrorPage from "./error-page.jsx";
import AuthWrapper from './AuthWrapper.jsx';
import "./index.css";

import Root, { loader as rootLoader, action as rootAction, } from "./routes/root.jsx";
import Dashboard from "./routes/dashboard.jsx";
import CrownJewel, {loader as crownJewelLoader} from "./routes/crownjewels.jsx";
import Login, {action as loginAction} from "./routes/login.jsx";
import PermissionTable from "./routes/permissionsDashboard.jsx";

import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { msalConfig } from './authConfig.js';

const msalInstance = new PublicClientApplication(msalConfig);
  

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
    loader: rootLoader,
    children: [
      { 
        index: true, 
        element: <AuthWrapper><Dashboard /></AuthWrapper>
      },
      { 
        path: "dashboard",
        element: <AuthWrapper><Dashboard /></AuthWrapper>
      },
      { 
        path: "permissions",
        element: <AuthWrapper><PermissionTable /></AuthWrapper>
      },
      { 
        path: "login",
        element: <Login />,
        action: loginAction,
      },
      {
        path: "crownjewels",
        element: <AuthWrapper><CrownJewel /></AuthWrapper>,
        //loader: crownJewelLoader,
      },
      {
        path: "attacksurface",
        element: <AuthWrapper><CrownJewel /></AuthWrapper>,
        //loader: crownJewelLoader,
      }
    ],
  },
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  //<React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <RouterProvider router={router} />
    </MsalProvider>
 // </React.StrictMode>
);