import { Outlet, NavLink, Link, useLoaderData, Form, redirect, useNavigation,} from "react-router-dom";
import ResourceDashboard from "./resourcedashboard"
import { MsalProvider, useMsal } from '@azure/msal-react';
import { useMsalAuthentication } from "@azure/msal-react";
import { InteractionType } from '@azure/msal-browser';

import React from 'react';

export async function loader() {
    return {  };
}

export default function Root() {
    const { instance, accounts, inProgress } = useMsal();
    if (accounts.length >= 0) {
        var account=accounts[0];

        //const navigation = useNavigation();
        return (
        <>
            <div id="sidebar">
            <img src="./logo.png"alt="Logo" />
            <div>
                <h2>Daze Cloud</h2>
            </div>
            
            <nav>
                <ul>
                    <li>
                        <NavLink
                                to={`dashboard`}
                                className={({ isActive, isPending }) =>
                                isActive
                                    ? "active"
                                    : isPending
                                    ? "pending"
                                    : ""
                                }
                        >
                            <Link to={`dashboard`}>
                                <i>Directory Insights</i>
                            </Link>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                                to={`permissions`}
                                className={({ isActive, isPending }) =>
                                isActive
                                    ? "active"
                                    : isPending
                                    ? "pending"
                                    : ""
                                }
                        >
                            <Link to={`permissions`}>
                                <i>Permission Dashboard</i>
                            </Link>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                                to={`crownjewels`}
                                className={({ isActive, isPending }) =>
                                isActive
                                    ? "active"
                                    : isPending
                                    ? "pending"
                                    : ""
                                }
                        >
                            <Link to={`crownjewels`}>
                                <i>Crown Jewels</i>
                            </Link>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                                to={`attacksurface`}
                                className={({ isActive, isPending }) =>
                                isActive
                                    ? "active"
                                    : isPending
                                    ? "pending"
                                    : ""
                                }
                        >
                            <Link to={`attacksurface`}>
                                <i>Attack Surface</i>
                            </Link>
                        </NavLink>
                    </li>
                </ul>
                 
            </nav>
            </div>

            
            <div id="detail" >
            <Outlet />
            </div>
        </>
        );
    } /*else if (inProgress === "login") {
        return <span>Login is currently in progress!</span>
    } else {
        return (
            <>
                <span>There are currently no users signed in!</span>
                <button onClick={() => instance.loginRedirect()}>Login</button>
            </>
        );
    }    

    <div>
                <Form method="post">
                    <button type="submit">New</button>
                </Form>
    </div>
    {contacts.length ? (
                    <ul>
                    {contacts.map((contact) => (
                        <li key={contact.id}>
                        <NavLink
                            to={`contacts/${contact.id}`}
                            className={({ isActive, isPending }) =>
                            isActive
                                ? "active"
                                : isPending
                                ? "pending"
                                : ""
                            }
                    >
                            <Link to={`contacts/${contact.id}`}>
                                {contact.first || contact.last ? (
                                <>
                                    {contact.first} {contact.last}
                                </>
                                ) : (
                                <i>No Name</i>
                                )}{" "}
                                {contact.favorite && <span>★</span>}
                            </Link>
                        </NavLink>
                        </li>
                    ))}
                    </ul>
                ) : (
                    <p>
                    <i>No contacts</i>
                    </p>
                )}*/
  }