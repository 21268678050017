import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useAppContext } from '../AuthWrapper';
import { useEffect, useState } from "react";
import { Client } from '@microsoft/microsoft-graph-client';



function ensureClient(authProvider) {
    
      var graphClient = Client.initWithMiddleware({
        defaultVersion: 'beta',
        authProvider: authProvider
      });
      
    return graphClient;
  }

async function getEntraAssignments(authProvider, graphClient) {
    //const graphClient = ensureClient(authProvider);
  
    const res = await graphClient.api('/roleManagement/directory/roleAssignmentSchedules')
      .expand('roleDefinition,principal')
      .get();
  
    return res;
}

async function getPimEligible(authProvider, graphClient) {
    //const graphClient = ensureClient(authProvider);
  
    const res = await graphClient.api('/roleManagement/directory/roleEligibilitySchedules')
      .expand('roleDefinition,principal')
      .get();
  
    return res;
}

async function getPimActive(authProvider, graphClient) {
    //const graphClient = ensureClient(authProvider);
    const res = await graphClient.api('/roleManagement/directory/roleAssignmentScheduleInstances')
    .query("$filter=assignmentType eq 'Activated'&$expand=roleDefinition,principal")
    .get();
    return res;
}

export default function PermissionTable() {
    const app = useAppContext();
    const [loading, setLoading] = useState(true);
    const [graphClient, setClient] = useState(null)
    const [users, setUsers] = useState(null);
    const [groups, setGroups] = useState(null);
    const [entraAssignments, setEntraAssignments] = useState(null);
    const [pimEligibleAssignments, setPimEligibleAssignments] = useState(null);
    const [pimActivatedRoles, setPimActivatedRoles] = useState(null);
    var loggedIn=false
    
    if (!graphClient){
        setClient(ensureClient(app.authProvider))
    }

    const getData = async() => {
            
            if (!entraAssignments){
                setEntraAssignments( await getEntraAssignments(app.authProvider, graphClient))     
            }  
            if (!pimEligibleAssignments){
                setPimEligibleAssignments( await getPimEligible(app.authProvider, graphClient))
            }  

            if (!pimActivatedRoles){
              setPimActivatedRoles( await getPimActive(app.authProvider, graphClient))
            }  

            setLoading(false)

    }

    const account = app.instance.getActiveAccount();
    if (account) {
        loggedIn=true
        
    }
    useEffect( () => {
        if (loggedIn == true) {
            getData()
            console.log('i fire once');
        }
        
    }, []);

    if (loading) {
        // Render loading component
        return (<h1>loading</h1>)
    } else if (entraAssignments) {
        // Render content that depends on data from your API
        return (
            <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                <TableHead>
                <TableRow>
                    <TableCell>System</TableCell>
                    <TableCell align="right">Principal Id</TableCell>
                    <TableCell align="right">Principal Name</TableCell>
                    <TableCell align="right">Principal Type</TableCell>
                    <TableCell align="right">Permission</TableCell>
                    <TableCell align="right">scope</TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                {entraAssignments.value.map((row) => (
                    <TableRow
                    key={row.name}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                    <TableCell component="th" scope="row">
                        {'PIM Active Role Assignments'}
                    </TableCell>
                    <TableCell align="right">{row.principalId}</TableCell>
                    <TableCell align="right">{row.principal.displayName}</TableCell>
                    <TableCell align="right">{row.principal["@odata.type"]}</TableCell>
                    <TableCell align="right">{row.roleDefinition.displayName}</TableCell>
                    <TableCell align="right">{row.directoryScopeId}</TableCell>
                    </TableRow>
                ))}
                {pimEligibleAssignments.value.map((row) => (
                    <TableRow
                    key={row.name}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                    <TableCell component="th" scope="row">
                        {'PIM Eligible Role Assignments'}
                    </TableCell>
                    <TableCell align="right">{row.principalId}</TableCell>
                    <TableCell align="right">{row.principal.displayName}</TableCell>
                    <TableCell align="right">{row.principal["@odata.type"]}</TableCell>
                    <TableCell align="right">{row.roleDefinition.displayName}</TableCell>
                    <TableCell align="right">{row.directoryScopeId}</TableCell>
                    </TableRow>
                ))}
                {pimActivatedRoles.value.map((row) => (
                    <TableRow
                    key={row.name}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                    <TableCell component="th" scope="row">
                        {'Current Activated PIM Entra Roles'}
                    </TableCell>
                    <TableCell align="right">{row.principalId}</TableCell>
                    <TableCell align="right">{row.principal.displayName}</TableCell>
                    <TableCell align="right">{row.principal["@odata.type"]}</TableCell>
                    <TableCell align="right">{row.roleDefinition.displayName}</TableCell>
                    <TableCell align="right">{row.directoryScopeId}</TableCell>
                    </TableRow>
                ))}
                </TableBody>
            </Table>
            </TableContainer>
        );
    }
    
    
}
