import { Outlet, NavLink, Link, Form, redirect,} from "react-router-dom";

export async function action() {
    const loginUrl="https://login.microsoftonline.com/"
    return redirect(loginUrl);
  }

export default function Login() {
    return (
      <>
        
        <div>
          <Form method="post">
              <button type="submit">Login With Entra</button>
          </Form>
        </div>
          
        
        <div id="detail" >
          <Outlet />
        </div>
      </>
    );
  }