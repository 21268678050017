import React, {
    useContext,
    createContext,
    useState,
    MouseEventHandler,
    useEffect
  } from 'react';
  import { AuthCodeMSALBrowserAuthenticationProvider } from '@microsoft/microsoft-graph-client/authProviders/authCodeMsalBrowser';
  import { InteractionType, PublicClientApplication, EventType } from '@azure/msal-browser';
  import { useMsal } from '@azure/msal-react';

import {loginRequest } from './authConfig'

const appContext = createContext({
  user: undefined,
  error: undefined,
  signIn: undefined,
  signOut: undefined,
  displayError: undefined,
  clearError: undefined,
  authProvider: undefined,
  authenticated: undefined,
  instance: undefined
  });

export function useAppContext() {
    return useContext(appContext);
}

export default function AuthWrapper({ children }) {
    const msal = useMsal();
    const auth = useProvideAppContext(msal);
    if (auth.authenticated){
      return (
          <appContext.Provider value={auth}>
            {children}
          </appContext.Provider>
        );
    }
    else {
      return (
        <>
            <h1>User is not currently logged in. Click here to log in:</h1>
            <button onClick={() => msal.instance.loginRedirect()}>Login</button>
        </>
      );
    }
  };



function useProvideAppContext(msal) {
    const [user, setUser] = useState(null);
    const [error, setError] = useState(null);
    var authenticated=false
    const displayError = (message, debug) => {
      setError({ message, debug });
    }
  
    const clearError = () => {
      setError(undefined);
    }
  
    const signIn = async () => {
      await msal.instance.loginRedirect(loginRequest)
    };

    // <AuthProviderSnippet>
    // Used by the Graph SDK to authenticate API calls
    const authProvider = new AuthCodeMSALBrowserAuthenticationProvider(
      msal.instance,
      {
        account: msal.instance.getActiveAccount(),
        scopes: loginRequest.scopes,
        interactionType: InteractionType.Redirect,
      }
      
    );
    
    // </AuthProviderSnippet>
    const account = msal.instance.getActiveAccount();
    if (!account) {
      const knownAccounts = msal.instance.getAllAccounts()

      if (knownAccounts.length == 0){
        
        //signIn() only sign in if there is a button
        //const knownAccounts = msal.instance.getAllAccounts()
      }
      else {
        authenticated=true
        msal.instance.setActiveAccount(knownAccounts[0])
      }
     
    }
    else { 
      authenticated=true
    }
    
    msal.instance.enableAccountStorageEvents();

    msal.instance.addEventCallback((event) => {
      if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
        const account = event.payload.account;
        msal.instance.setActiveAccount(account);
      }
    });
  
    var instance = msal.instance

    const signOut = async () => {
      await msal.instance.logoutRedirect();
      setUser(undefined);
    };

    return {
      user,
      error,
      signIn,
      signOut,
      displayError,
      clearError,
      authProvider,
      authenticated,
      instance
    };
  }