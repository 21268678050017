import { Form, useLoaderData } from "react-router-dom";
import { useAppContext } from '../AuthWrapper';


export default function CrownJewel() {
  const app = useAppContext();
  return (
    <></>
  );
    
}
